<template>
  <div>
      <div class="topimg">
          <img :src="img" style="width:100%" alt="">
      </div>
      <router-view style="height:100%"></router-view>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user";
export default {
  data(){
    return{
        img:''
    }
  },
  created() {
    this.getcompany();
  },
  methods: {
   
    async getcompany() {
      let params = {
        barId:6,
      };
      const data = await getbannerdetail(params);
      this.img = "http://36.152.65.166:10008" + data.data.image;
     
      // console.log(data.data);
    },
  },
}

</script>

<style>

</style>